// import React, { useEffect, useRef, useState } from "react";
// import "../../css/style.css";
// import "../../css/responsive.css";
// import "../../css/custom/theme-2.css";
// import "../../images/favicon.ico"
// import "../../css/animate.css";
// import Slider from "react-slick";
// import teamImage from "../../images/team/1.jpg"
// import { useData } from "../../context/Provider";



// const API_BASE_URL = process.env.REACT_APP_API_URL;
// const TeamSection = () => {
//     const { experts } = useData();
//     const [activeSlide, setActiveSlide] = useState(0);
//     const sliderRef = useRef(null);

//     useEffect(() => {
//         if (sliderRef.current) {
//             sliderRef.current.slickGoTo(activeSlide);
//         }
//     }, [activeSlide]);

    
// const NextArrow = ({ onClick }) => (
//     <button  onClick={onClick} className="next-mains">
//     </button>
//   );
  
//   const PrevArrow = ({ onClick }) => (
//     <button onClick={onClick} className="prev-mains">
//     </button>
//   );
  

//     function SampleNextArrow(props) {
//         const { className, style, onClick } = props;
//         return (
//             // <div
//             //     className={className}
//             //     style={{ ...style, display: "block", background: "black" }}
//             //     onClick={onClick}
//             // />
//             <button  onClick={onClick} className="next-mains">  &gt;</button>
//         );
//     }

//     function SamplePrevArrow(props) {
//         const { className, style, onClick } = props;
//         return (
//             <button onClick={onClick} className="prev-mains">   &lt;
//             </button>
//         );
//     }

//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 0,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         waitForAnimate: false,
//         nextArrow: <SampleNextArrow />,
//         prevArrow: <SamplePrevArrow />,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     infinite: true,
//                     dots: true,
//                 },
//             },
//             {
//                 breakpoint: 800,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 0,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     const handlePagerClick = (index) => {
//         setActiveSlide(index);
//     };


//     return (
//         <>
//             <section className="our-team sec-pad"  >
//                 <div className="container">
//                     <div className="team-title centered">
//                         <div className="section-title"><h2>Our Experts</h2></div>
//                         <div className="title-text"><p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusm temp </p></div>
//                     </div>
//                     <div className="row">
//                         <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
//                             <div className="bxslider " >
//                                 <Slider {...settings}>
//                                     {experts.map((item, index) => (
//                                         <div className="row" key={index}>
//                                             <div className="col-md-5 col-sm-5 col-xs-12 team-colmun">
//                                                 <div className="clearfix">
//                                                     <figure className="img-box pull-left">
//                                                         <a href="team"><img src={`${API_BASE_URL}/ourExperts/${item.Image}`} alt="image" /></a>
//                                                     </figure>
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-7 col-sm-7 col-xs-12 team-colmun">
//                                                 <div className="team-content">
//                                                     <div className="team-info">
//                                                         <div className="team-text"><a href="team">{item.Name}</a></div>
//                                                         <span>{item.Designation}</span>
//                                                     </div>
//                                                     <div className="text">
//                                                         <p>{item.Description}</p>
//                                                     </div>
//                                                     <div className="team-contact">
//                                                         {/* <div className="text"><strong>Phone:</strong>&nbsp; &nbsp;{item.Phone}</div> */}
//                                                         <div className="text"><strong>Email:</strong>&nbsp; &nbsp;{item.Email}</div>
//                                                     </div>
//                                                     <ul className="team-social">
//                                                         <li><a href="#" className="active"><i className="fa fa-twitter"></i></a></li>
//                                                         <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
//                                                         <li><a href="#"><i className="fa fa-facebook"></i></a></li>
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     ))}

//                                 </Slider>
//                             </div>
//                             <div class="slider-pager" >
//                                 <div class="center">
//                                     <ul class="nav-link list-inline">
//                                         <li id="slider-prev"></li>
//                                         <li id="slider-next"></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
//                             <div className="row">
//                                 <div className="col-md-5">
//                                     <div className="slider-pager">
//                                         <ul className="list-inline thumb-box style2">
//                                             {experts.map((item, index) => (
//                                                 <li key={index}>
//                                                     <a
//                                                         className={index === activeSlide ? 'active' : ''}
//                                                         onClick={() => handlePagerClick(index)}
//                                                     >
//                                                         {/* <figure><img src={`${API_BASE_URL}/ourExperts/${item.Small_Image}`} alt="" /></figure> */}
//                                                     </a>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>


//         </>
//     )
// }
// export default TeamSection;



// {/* <div className="slider-pager">
//                                 <div className="center">
//                                     <ul className="nav-link list-inline">
//                                         <li onClick={() => setCurrentSlide(currentSlide - 1)} id="slider-prev">prev</li>
//                                         <li onClick={() => setCurrentSlide(currentSlide + 1)} id="slider-next">next</li>
//                                     </ul>
//                                 </div>
//                             </div> */}



// //////// code start here

// import React, { useEffect, useRef, useState } from "react";
// import "../../css/style.css";
// import "../../css/responsive.css";
// import "../../css/custom/theme-2.css";
// import "../../images/favicon.ico";
// import "../../css/animate.css";
// import Slider from "react-slick";
// import { useData } from "../../context/Provider";
// import { faTwitter, faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const API_BASE_URL = process.env.REACT_APP_API_URL;

// const TeamSection = () => {
//     const { experts } = useData();
//     const [activeSlide, setActiveSlide] = useState(0);
//     const sliderRef = useRef(null);

//     useEffect(() => {
//         if (sliderRef.current) {
//             sliderRef.current.slickGoTo(activeSlide);
//         }
//     }, [activeSlide]);

//     const NextArrow = ({ onClick }) => (
//         <button onClick={onClick} className="next-mains">
//             <i className="fas fa-chevron-right"></i>
//         </button>
//     );

//     const PrevArrow = ({ onClick }) => (
//         <button onClick={onClick} className="prev-mains">
//             <i className="fas fa-chevron-left"></i>
//         </button>
//     );

//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 0,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         waitForAnimate: false,
//         nextArrow: <NextArrow />,
//         prevArrow: <PrevArrow />,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     infinite: experts.length > 1,
//                     dots: true,
                    
//                 },
//             },
//             {
//                 breakpoint: 800,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 0,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     const handlePagerClick = (index) => {
//         setActiveSlide(index);
//     };
//     // console.log('ourexpertHome:::',experts);

//     return (
//         <>
//             <section className="our-team sec-pad">
//                 <div className="container">
//                     <div className="team-title centered">
//                         <div className="section-title">
//                             <h2>Our Experts</h2>
//                         </div>
//                         <div className="title-text">
//                             <p>Exceptional experts drive innovation, strategy, and proven results across industries.</p>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
//                             <div className="bxslider">
//                                 <Slider {...settings} ref={sliderRef}>
//                                     {experts.map((item, index) => (
//                                         <div className="row" key={index}>
//                                             <div className="col-md-5 col-sm-5 col-xs-12 team-colmun">
//                                                 <div className="clearfix">
//                                                     <figure className="img-box pull-left">
//                                                         <a href="team"><img src={`${API_BASE_URL}/ourExperts/${item.Image}`} alt="image" /></a>
//                                                     </figure>
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-7 col-sm-7 col-xs-12 team-colmun">
//                                                 <div className="team-content">
//                                                     <div className="team-info">
//                                                         <div className="team-text"><a href="team">{item.Name}</a></div>
//                                                         <span>{item.Designation}</span>
//                                                     </div>
//                                                     <div className="text"
//                                                     dangerouslySetInnerHTML={{ __html: item?.Description }} >
//                                                         {/* <p>{item.Description}</p> */}
//                                                     </div>
//                                                     <div className="team-contact">
//                                                         <div className="text"><strong>Email:</strong>&nbsp; &nbsp;{item.Email}</div>
//                                                     </div>
//                                                     {/* <ul className="team-social">
//                                                         <li><a href="#" className="active"><i className="fa fa-twitter"></i></a></li>
//                                                         <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
//                                                         <li><a href="#"><i className="fa fa-facebook"></i></a></li>
//                                                     </ul> */}
//                                                      <ul className="team-social">
//                                                         {/* <li><a href="#" className="active"><FontAwesomeIcon icon={faTwitter} /></a></li> */}
//                                                         <li>
//                                                             <a href={item.x_link}  target="_blank" >
//                                                                 <FontAwesomeIcon icon={faTwitter}/>
//                                                             </a></li>
//                                                          {/* <li><a href={item.linkedin_link} target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li> */}
//                                                          <li><a href={item.linkedin_link} target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
//                                                         <li><a href={item.fb_link} target="_blank" ><FontAwesomeIcon icon={faFacebook} /></a></li>

//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </Slider>
//                             </div>
//                             <div className="slider-pager">
//                                 <div className="center">
//                                     <ul className="nav-link list-inline">
//                                         <li id="slider-prev"></li>
//                                         <li id="slider-next"></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
//                             <div className="row">
//                                 <div className="col-md-5">
//                                     <div className="slider-pager">
//                                         <ul className="list-inline thumb-box style2">
//                                             {experts.map((item, index) => (
//                                                 <li key={index}>
//                                                     <a
//                                                         className={index === activeSlide ? 'active' : ''}
//                                                         onClick={() => handlePagerClick(index)}
//                                                     >
//                                                     </a>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default TeamSection;


import React, { useEffect, useRef, useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/custom/theme-2.css";
import "../../images/favicon.ico";
import "../../css/animate.css";
import Slider from "react-slick";
import { useData } from "../../context/Provider";
import { faTwitter, faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const TeamSection = () => {
    const { experts } = useData();
    const [activeSlide, setActiveSlide] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(activeSlide);
        }
    }, [activeSlide]);

    const NextArrow = ({ onClick }) => (
        <button onClick={onClick} className="next-mains">
            <i className="fas fa-chevron-right"></i>
        </button>
    );

    const PrevArrow = ({ onClick }) => (
        <button onClick={onClick} className="prev-mains">
            <i className="fas fa-chevron-left"></i>
        </button>
    );

    const settings = {
        dots: false,
        infinite: experts.length > 1, // Ensure infinite is true only if there are multiple items
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        waitForAnimate: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: experts.length > 1,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handlePagerClick = (index) => {
        setActiveSlide(index);
    };

    return (
        <section className="our-team sec-pad">
            <div className="container">
                <div className="team-title centered">
                    <div className="section-title">
                        <h2>Our Experts</h2>
                    </div>
                    <div className="title-text">
                        <p>Exceptional experts drive innovation, strategy, and proven results across industries.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
                        {experts.length > 0 ? (
                            <div className="bxslider">
                                <Slider {...settings} ref={sliderRef}>
                                    {experts.map((item, index) => (
                                        <div key={index}>
                                            <div className="row">
                                                <div className="col-md-5 col-sm-5 col-xs-12 team-colmun">
                                                    <div className="clearfix">
                                                        <figure className="img-box pull-left">
                                                            <a href="team"><img src={`${API_BASE_URL}/ourExperts/${item.Image}`} alt="image" /></a>
                                                        </figure>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-sm-7 col-xs-12 team-colmun">
                                                    <div className="team-content">
                                                        <div className="team-info">
                                                            <div className="team-text"><a href="team">{item.Name}</a></div>
                                                            <span>{item.Designation}</span>
                                                        </div>
                                                        <div className="text" dangerouslySetInnerHTML={{ __html: item.Description }} />
                                                        <div className="team-contact">
                                                            <div className="text"><strong>Email:</strong>&nbsp; &nbsp;{item.Email}</div>
                                                        </div>
                                                        <ul className="team-social">
                                                            <li><a href={item.twitter_link} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                                            <li><a href={item.linkedin_link} target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                                                            <li><a href={item.fb_link} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                            <div>No experts found.</div>
                        )}
                        <div className="slider-pager">
                            <div className="center">
                                <ul className="nav-link list-inline">
                                    <li id="slider-prev"></li>
                                    <li id="slider-next"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 col-sm-12 col-xs-12 col-md-offset-1 team-colmun">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="slider-pager">
                                    <ul className="list-inline thumb-box style2">
                                        {experts.map((item, index) => (
                                            <li key={index}>
                                                <a
                                                    className={index === activeSlide ? 'active' : ''}
                                                    onClick={() => handlePagerClick(index)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamSection;
