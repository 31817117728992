import React from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../css/animate.css";
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook,faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
 
const API_BASE_URL = process.env.REACT_APP_API_URL;
const TeamCard = ({data}) => {
    return (
        <>
    <section className="team-page centered">
        <div className="container">
            {/* <div className="row d-flex justify-content-center our_team_container"> */}
            <div className="our_team_container">
                {data?.map((item , index)=>(
                <div className="col-md-3 col-sm-6 col-xs-12 team-colmun" key={index}>
                    <div className="single-item">
                        <div className="img-box"><figure><img src={`${API_BASE_URL}/teams/${item.Image}`} alt=""/></figure></div>
                        <div className="lower-content">
                            <ul className="team-social">
                                    <li><a href={item.Linkedin} target="_blank" ><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                    <li><a href={item.FaceBook} target="_blank" ><FontAwesomeIcon icon={faFacebook} /></a></li>
                                    <li><a href={item.Twitter} target="_blank" ><FontAwesomeIcon icon={faTwitter} /></a></li>
 
                                {/* <li><a href="#" className="active"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li> */}
                            </ul>
                            <div className="team-info">
                                <h4>{item?.Name}</h4>
                                <span>{item?.Designation}</span>
                                <span>{item?.Designation2}</span>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </section>
        </>
    )
}
export default TeamCard;