//import CookiePolicy from './pages/Cookies/Cookies';
import PageRoutes from './routes';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CookieConsent from './components/CookieConsent';
import { Routes, Route } from 'react-router-dom';

// Suppress all console warnings and errors
console.warn = () => {};
console.error = () => {};
function App() {
  return (
    <><div>
      <PageRoutes />
     {/* <CookiePolicy/> */}
     <CookieConsent />
    </div>
    </>
  );
}

 
export default App;




