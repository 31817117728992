import React from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../css/animate.css";
import Preloader from "../../../shares/Preloader";
import TitleBread from "../../../shares/TitleAndBreadCrumb";
import TeamCard from "./TeamCard";
import { useData } from "../../../context/Provider";
import DynamicMetaTags from "../../../components/DynamicMetaTags";

const Team = () => {
    const { fTeamData, fHeadingImagesData } = useData();
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const teamImageUrl = `${API_BASE_URL}/assets/${fHeadingImagesData?.Team_Image}`;


    return (
        <>
          <DynamicMetaTags page="team" />
          <Preloader />
    
          <div className="ourTeam-bg centered" style={{ backgroundImage: `url(${teamImageUrl})` }} >
            <div className="container">
              {/* <div className="text">GET.rx</div> */}
            </div>
          </div>
    
          {/* <!-- bread-crumb --> */}
          <div className="bread-crumb">
            <div className="container">
              <div className="text"><a href="/">Home</a><i className="fa fa-angle-right"></i> Our Team
                {/* <div className="share"><i className="fa fa-share-alt" aria-hidden="true"></i>Share</div> */}
              </div>
            </div>
          </div>
          <TeamCard data={fTeamData} />
    
        </>
      );



};

export default Team;
