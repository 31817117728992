// import React from "react";
// import "../../../css/style.css";
// import "../../../css/responsive.css";
// import "../../../css/custom/theme-2.css";
// import "../../../images/favicon.ico";
// import "../../../css/animate.css";
// import { Link } from "react-router-dom";
// //import { icon } from "@fortawesome/fontawesome-svg-core";
// const API_BASE_URL = process.env.REACT_APP_API_URL;

// const defaultData = [

    
//     {
//         id: "12",
//         Title: "Financial Advise",
//         Description: "Lorem ipsum dolor sit amet consectetur adipisicing sed do eiusmotempor incididunt ut labore",
//         Icon: "finance.png"
//     }
// ]

// const handleLinkClick = (event) => {
//     event.preventDefault();
//     window.location.reload();
//     alert('clicked');
// };

// const OurServicesCard = ({ data }) => {
//     const datas = data && data.length > 0 ? data : defaultData
//     return (
//         <>
//             <section className="service-style-tow">
//                 <div className="container">
//                     <div className="row">
//                         {datas?.map((item, index) => (
//                             <div className="col-md-4 col-sm-6 col-xs-12 service-colmun" key={index}>
//                                {/* <Link to={`/service/${item.id}/${encodeURIComponent(item.Title.replace(/\s+/g, '-'))}`}> */}
                              
//                                <a href={`/service/${item.Page_url}`} onClick={handleLinkClick}>${item.Title}</a>
//                                {/* <Link to={`/service/${item.Page_url}`}> */}
//                                     <div className="service-content" >
//                                         <div className="icon-box">
                                            
//                                         <img
//                         src={`${API_BASE_URL}/ourServices/${item.Icon}`}
//                         alt={`Sponsor ${index}`}
//                       />
//                                             {/* <a href="service"><i className="flaticon-graph"></i></a> */}
                                            
                                            
//                                             </div>
                                        
//                                         <h4><a href="service">{item.Title.length > 80 ? `${item.Title.substring(0, 80)}...` : item.Title}</a></h4>
//                                         <div className="text"><p>{item.Description.length > 150 ? `${item.Description.substring(0, 150)}...` : item.Description}</p></div>
//                                     </div>
//                                 {/* </Link> */}
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </section>

//         </>
//     )
// }
// export default OurServicesCard;

// import React from "react";
// import "../../../css/style.css";
// import "../../../css/responsive.css";
// import "../../../css/custom/theme-2.css";
// import "../../../images/favicon.ico";
// import "../../../css/animate.css";
// import { Link } from "react-router-dom";
// import { useParams, useNavigate } from "react-router-dom";


// const API_BASE_URL = process.env.REACT_APP_API_URL;

// const defaultData = [
//     {
//         id: "12",
//         Title: "Financial Advise",
//         Description: "Lorem ipsum dolor sit amet consectetur adipisicing sed do eiusmotempor incididunt ut labore",
//         Icon: "finance.png"
//     }
// ];

// // useEffect(() => {
// //     if (!Page_url && fHomeAllOurServicesData && fHomeAllOurServicesData.length > 0) {
// //         setServiceTitle(fHomeAllOurServicesData[0].Page_url);
// //     } else {
// //         setServiceTitle(Page_url);
// //     }
// // }, [Page_url, fHomeAllOurServicesData]);

// const navigate = useNavigate();

// const handleLinkClick = (event,pageUrl) => {
//     event.preventDefault();
//  //   setServiceTitle(pageUrl);
//     navigate(`/service/${pageUrl}`);
//     window.location.reload();
//     alert('clicked');
// };

// const OurServicesCard = ({ data }) => {

//     const datas = data && data.length > 0 ? data : defaultData;
//     return (
//         <section className="service-style-tow">
//             <div className="container">
//                 <div className="row">
//                     {datas.map((item, index) => (
//                         <div className="col-md-4 col-sm-6 col-xs-12 service-colmun" key={index}>
//                             <a 
//                             // href={`/service/${item.Page_url}`} 
//                             onClick={handleLinkClick}>
//                                 <div className="service-content">
//                                     <div className="icon-box">
//                                         <img
//                                             src={`${API_BASE_URL}/ourServices/${item.Icon}`}
//                                             alt={`Service ${index}`}
//                                         />
//                                     </div>
//                                     <h4>{item.Title.length > 80 ? `${item.Title.substring(0, 80)}...` : item.Title}</h4>
//                                     <div className="text">
//                                         <p>{item.Description.length > 150 ? `${item.Description.substring(0, 150)}...` : item.Description}</p>
//                                     </div>
//                                 </div>
//                             </a>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </section>
//     );
// };

// export default OurServicesCard;



import React from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../images/favicon.ico";
import "../../../css/animate.css";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const defaultData = [
    // {
    //     id: "12",
    //     Title: "Financial Advise",
    //     Description: "Lorem ipsum dolor sit amet consectetur adipisicing sed do eiusmotempor incididunt ut labore",
    //     Icon: "finance.png"
    // }

    {
        "id": "1",
        "Title": "Custom Software Solutions",
        "Page_url": "custom-software-solutions",
        "Description": "Our Custom Software Solutions are designed to meet your unique business needs. Our expert team develops tailored software to enhance operational efficiency, streamline processes, and drive growth. We work closely with you to create innovative, scalable, and reliable solutions that align with your business goals and requirements.",
        "Icon": "custom_software.png",
        "TextEditor": "<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/1.jpg\" /></p>\r\n<p>Praesent nec nisl iaculis</p>\r\n<p>Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beataevitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptassit aspernatur aut odit aut fugit sed quia consequuntur&nbsp;</p>\r\n<p>Magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore etdolore magnam aliquam quaerat voluptatem labore.</p>\r\n<h4>Tincidunt ante enim posuere</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis</p>\r\n<ul>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida tempus.</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida</p>\r\n\t</li>\r\n</ul>\r\n<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/2.jpg\" /></p>\r\n<h4>Mauris ullamcorper feugiat</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut pers piciatisunde omnis iste natus error.sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt&nbsp;</p>\r\n<p>ExplicaboNemo enim ipsam voluptatemquiavoluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>",
        "Banner_Image": "service_details.png"
    },
    {
        "id": "2",
        "Title": "Network-Level Solutions",
        "Page_url": "network-level-solutions",
        "Description": "Experience peak connectivity and performance with our Network-Level Solutions. We specialize in tailored network design, implementation, security, and management. Enhance reliability, scalability, and security for seamless communication and efficient operations across your organization.",
        "Icon": "network.png",
        "TextEditor": "<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/1.jpg\" /></p>\r\n<p>Praesent nec nisl iaculis</p>\r\n<p>Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beataevitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptassit aspernatur aut odit aut fugit sed quia consequuntur&nbsp;</p>\r\n<p>Magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore etdolore magnam aliquam quaerat voluptatem labore.</p>\r\n<h4>Tincidunt ante enim posuere</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis</p>\r\n<ul>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida tempus.</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida</p>\r\n\t</li>\r\n</ul>\r\n<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/2.jpg\" /></p>\r\n<h4>Mauris ullamcorper feugiat</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut pers piciatisunde omnis iste natus error.sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt&nbsp;</p>\r\n<p>ExplicaboNemo enim ipsam voluptatemquiavoluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>",
        "Banner_Image": null
    },
    {
        "id": "3",
        "Title": "Fintech Services",
        "Page_url": "fintech-services",
        "Description": "Our FinTech Services deliver cutting-edge solutions for the financial sector, enhancing efficiency, security, and user experience. We offer customized software for digital banking, payment processing, fraud detection, and financial analytics. Partner with us to streamline operations and drive growth with innovative technology in the evolving financial landscape",
        "Icon": "finance.png",
        "TextEditor": "<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/1.jpg\" /></p>\r\n<p>Praesent nec nisl iaculis</p>\r\n<p>Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beataevitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptassit aspernatur aut odit aut fugit sed quia consequuntur&nbsp;</p>\r\n<p>Magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore etdolore magnam aliquam quaerat voluptatem labore.</p>\r\n<h4>Tincidunt ante enim posuere</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis</p>\r\n<ul>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida tempus.</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida</p>\r\n\t</li>\r\n</ul>\r\n<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/2.jpg\" /></p>\r\n<h4>Mauris ullamcorper feugiat</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut pers piciatisunde omnis iste natus error.sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt&nbsp;</p>\r\n<p>ExplicaboNemo enim ipsam voluptatemquiavoluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>",
        "Banner_Image": null
    },
    {
        "id": "4",
        "Title": "IT & Infrastructure Solutions",
        "Page_url": "IT-and-infrastructure-solutions",
        "Description": "Transform your business with our IT & Infrastructure Solutions as a service. From cloud migration to network optimization, security, and support, we offer comprehensive services tailored to your needs. Boost efficiency, reliability, and scalability while reducing costs with our expert solutions, ensuring seamless operations for your organization.",
        "Icon": "infrastructure.png",
        "TextEditor": "<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/1.jpg\" /></p>\r\n<p>Praesent nec nisl iaculis</p>\r\n<p>Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beataevitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptassit aspernatur aut odit aut fugit sed quia consequuntur&nbsp;</p>\r\n<p>Magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore etdolore magnam aliquam quaerat voluptatem labore.</p>\r\n<h4>Tincidunt ante enim posuere</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis</p>\r\n<ul>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida tempus.</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida</p>\r\n\t</li>\r\n</ul>\r\n<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/2.jpg\" /></p>\r\n<h4>Mauris ullamcorper feugiat</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut pers piciatisunde omnis iste natus error.sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt&nbsp;</p>\r\n<p>ExplicaboNemo enim ipsam voluptatemquiavoluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>",
        "Banner_Image": null
    },
    {
        "id": "5",
        "Title": "Research & Analysis",
        "Page_url": "research-and-analysis",
        "Description": "Elevate your strategy with our Research & Analysis services encompassing market trends, technological advancements, and innovation insights. Gain a competitive edge with in-depth analyses tailored to your industry, enabling informed decisions and agile adaptation. Stay ahead of the curve and drive growth in today's dynamic landscape.",
        "Icon": "research.png",
        "TextEditor": "<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/1.jpg\" /></p>\r\n<p>Praesent nec nisl iaculis</p>\r\n<p>Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beataevitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptassit aspernatur aut odit aut fugit sed quia consequuntur&nbsp;</p>\r\n<p>Magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore etdolore magnam aliquam quaerat voluptatem labore.</p>\r\n<h4>Tincidunt ante enim posuere</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis</p>\r\n<ul>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida tempus.</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida</p>\r\n\t</li>\r\n</ul>\r\n<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/2.jpg\" /></p>\r\n<h4>Mauris ullamcorper feugiat</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut pers piciatisunde omnis iste natus error.sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt&nbsp;</p>\r\n<p>ExplicaboNemo enim ipsam voluptatemquiavoluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>",
        "Banner_Image": null
    },
    {
        "id": "6",
        "Title": "Consultancy Services",
        "Page_url": "consultancy-services",
        "Description": "Unlock your potential with our Consultancy Services spanning software, infrastructure, networks, and business development. Leverage our expertise to optimize operations, enhance security, and drive growth strategies. From system integration to market expansion, we provide tailored solutions for your organization's success in today's competitive landscape.",
        "Icon": "consultancy.png",
        "TextEditor": "<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/1.jpg\" /></p>\r\n<p>Praesent nec nisl iaculis</p>\r\n<p>Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beataevitae dicta sunt explicabo nemo enim ipsam voluptatem quia voluptassit aspernatur aut odit aut fugit sed quia consequuntur&nbsp;</p>\r\n<p>Magni dolores eos qui ratione voluptatem sequi nesciunt neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore etdolore magnam aliquam quaerat voluptatem labore.</p>\r\n<h4>Tincidunt ante enim posuere</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis</p>\r\n<ul>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida tempus.</p>\r\n\t</li>\r\n\t<li>\r\n\t<p>Morbi fermentum felis nec gravida</p>\r\n\t</li>\r\n</ul>\r\n<p><img alt=\"\" src=\"http://html.commonsupport.xyz/2018/TheBiznes/images/service/2.jpg\" /></p>\r\n<h4>Mauris ullamcorper feugiat</h4>\r\n<p>Excepteur sint occaecat cupidatat non proident sunt culpa qui officia deserunt mollit anim id est laborum. Sed ut pers piciatisunde omnis iste natus error.sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt&nbsp;</p>\r\n<p>ExplicaboNemo enim ipsam voluptatemquiavoluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.</p>",
        "Banner_Image": null
    }

];

const OurServicesCard = ({ data }) => {
    const navigate = useNavigate();
    const datas = data && data.length > 0 ? data : defaultData;

    const handleLinkClick = (event, pageUrl) => {
        event.preventDefault();
        navigate(`/service/${pageUrl}`);
        window.location.reload();
        // alert('clicked');
    };

    return (
        <section className="service-style-tow">
            <div className="container">
                <div className="row">
                    {datas.map((item, index) => (
                        <div className="col-md-4 col-sm-6 col-xs-12 service-colmun" key={index}>
                            <a href="#" onClick={(e) => handleLinkClick(e, item.Page_url)}>
                                <div className="service-content" style={{height:'450px'}}>
                                    <div className="icon-box">
                                        <img 
                                         style={{
                                            imageRendering: 'auto',
                                            imageRendering: 'crisp-edges', 
                                            imageRendering: -'moz-crisp-edges', 
                                            iimageRendering: '-o-crisp-edges',
                                            imageRendering: '-webkit-optimize-contrast',
                                            width:'52px',
                                          }}

                                            src={`${API_BASE_URL}/ourServices/${item.Icon}`}
                                            alt={`Service ${index}`}
                                        />
                                    </div>
                                    <h4>{item.Title.length > 100 ? `${item.Title.substring(0, 100)}...` : item.Title}</h4>
                                    <div className="text">
                                        <p>{item.Description.length > 400 ? `${item.Description.substring(0, 400)}...` : item.Description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OurServicesCard;
