import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/custom/theme-2.css";
import "../../images/favicon.ico";
import "../../css/animate.css";
import aboutImage from "../../images/about/1.jpg";
import FeaturesCard from "../Home/FeatureCard";
import HomeTestimonials from "../Home/HomeTestimonials";
import Preloader from "../../shares/Preloader";
import FactCounter from "../Home/Factcounter";
import TeamSection from "../Home/TeamSection";
import Features from "../Home/Features";
import { useData } from "../../context/Provider";
import DynamicMetaTags from "../../components/DynamicMetaTags";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const AboutUs = () => {
  const { 
    fHomeSlide, 
    fHomeWhyGetData, 
    fHomeOurServicesData, 
    fHomeAllOurServicesData, 
    fHomeOurServicesSection,
    aboutUsData, 
    fHeadingImagesData,
    fTeams,
    testimonials
  } = useData();
  

  return (
    <>
      <DynamicMetaTags page="about-us" />
      <Preloader />

      <div className="about-bg centered" style={{ backgroundImage: `url(${`${API_BASE_URL}/assets/${fHeadingImagesData?.About_Image}`})` }}>
        <div className="container">
          {/* <div className="text">GET.rx</div> */}
        </div>
      </div>

      {/* <!-- bread-crumb --> */}
      <div className="bread-crumb">
        <div className="container">
          <div className="text"><a href="/">Home</a><i className="fa fa-angle-right"></i> About us
            {/* <div className="share"><i className="fa fa-share-alt" aria-hidden="true"></i>Share</div> */}
          </div>
        </div>
      </div>

      {/* <!-- bread crumb end --> */}
      {/* <!-- about section --> */}
      <section className="about-section sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 about-colmun">
              <div className="about-content">
                <div className="about-title">
                  <div className="section-title"><h2>{aboutUsData?.Title}</h2></div>
                  <div className="title-text"></div>
                  <div style={{ paddingTop: '67px',fontSize: '21px', fontFamily: 'Montserrat'}}>{aboutUsData?.Footer_AboutUs}</div>

                </div>
                <div style={{paddingTop:'100px'}} dangerouslySetInnerHTML={{ __html: aboutUsData?.Description }} />
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 about-colmun">
              <div className="img-box"><figure><img src={`${API_BASE_URL}/aboutus/${aboutUsData?.Image}`} alt="" /></figure></div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- about section end --> */}
      <HomeTestimonials />
      <FeaturesCard data={fHomeOurServicesSection} CardData={fHomeAllOurServicesData} />
      <TeamSection />

    </>
  );
};

export default AboutUs;
