import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Icon from "../../images/icons/Icon.png";

const OpenStreetMap = () => {
  useEffect(() => {
    const mapId = "contact-map";

    // Check if a map is already initialized
    if (L.DomUtil.get(mapId)._leaflet_id) {
      return;
    }

    const mapElement = document.getElementById(mapId);
    if (!mapElement) return;

    const mapLat = parseFloat(mapElement.getAttribute("data-map-lat"));
    const mapLng = parseFloat(mapElement.getAttribute("data-map-lng"));
    const mapZoom = parseInt(mapElement.getAttribute("data-map-zoom"), 10) || 15;
    const mapTitle = mapElement.getAttribute("data-map-title");
    const markers = JSON.parse(mapElement.getAttribute("data-markers"));
    const iconPath = mapElement.getAttribute("data-icon-path");

    // Initialize map only if it's not already created
    // const map = L.map(mapId).setView([mapLat, mapLng], mapZoom);
 

    const map = L.map(mapId, {
        attributionControl: false, // Disable attribution
      }).setView([mapLat, mapLng], mapZoom);

      
    // Add OpenStreetMap tile layer
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "&copy; OpenStreetMap contributors",
    }).addTo(map);
 

    if (iconPath && markers) {
      markers.forEach((marker) => {
        const position = [parseFloat(marker[0]), parseFloat(marker[1])];
        const contentString = marker[2] || "";

        const customIcon = L.icon({
          iconUrl: iconPath,
          iconSize: [32, 32],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
        });

        const markerInstance = L.marker(position, { icon: customIcon }).addTo(map);

        if (contentString) {
          markerInstance.bindPopup(contentString);
        }
      });
    }
  }, []);

  return (
    <div
      id="contact-map"
      className="leaflet-map"
      style={{ height: "400px", width: "100%", border: "0", borderRadius: "10px" }}
      data-map-lat="-17.760662248390584"
      data-map-lng="31.05710158873928"
      data-map-zoom="15"
      data-markers='[["-17.760662248390584", "31.05710158873928", "GETransfer Pvt Ltd", "icon.png"]]'
      data-icon-path={Icon}
      data-map-title="GETransfer Pvt Ltd"
    />
  );
};

export default OpenStreetMap;