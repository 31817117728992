import React from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../images/favicon.ico";
import "../../../css/animate.css";
import Preloader from "../../../shares/Preloader";
import OurServicesCard from "./OurServicesCard";
import TeamSection from "../../Home/TeamSection";
import CallBack from "../../Home/CallBack";
import { useData } from "../../../context/Provider";
import DynamicMetaTags from "../../../components/DynamicMetaTags";

const API_BASE_URL = process.env.REACT_APP_API_URL;
// console.log('API_BASE_URL', API_BASE_URL);

const OurServices = () => {
    const { fHomeAllOurServicesData, fHeadingImagesData } = useData();

    // Handler to reload the page
    const handleLinkClick = (event) => {
        event.preventDefault();
        window.location.reload();
    };

    return (
        <>
            <Preloader />
            <DynamicMetaTags page="services" />
            <div
                className="ourservices-bg centered"
                style={{ backgroundImage: `url(${API_BASE_URL}/assets/${fHeadingImagesData?.OurServices_Image})` }}
            >
                <div className="container"></div>
            </div>

            <div className="bread-crumb">
                <div className="container">
                    <div className="text">
                        <a href="/" onClick={handleLinkClick}>Home</a>
                        <i className="fa fa-angle-right"></i> Our Services
                        {/* <div className="share">
                            <i className="fa fa-share-alt" aria-hidden="true"></i>Share
                        </div> */}
                    </div>
                </div>
            </div>
            <OurServicesCard data={fHomeAllOurServicesData} />
            <CallBack />
            <TeamSection />
        </>
    );
};

export default OurServices;
