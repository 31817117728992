import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    fAboutUs,
    fContactUsValues,
    fExperts,
    fFaqsDetails,
    fHeadingImages,
    fHomeAllOurServices,
    fHomeCallBack,
    fHomeOurServices, 
    fHomeProject,
    fHomeWhyGet,
    fSponsors,
    fTeams,
    fTestimonials,
    fHomeSlider
} from "./api";



  const fetchData = async (fetchFunction, setter) => {
    try {
      const response = await fetchFunction();
      const result = response.result;
      setter(result);
    } catch (error) {
      console.log(error);
    }
  };


const DataContext = createContext();

export const useData = () => {
    return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
    const location = useLocation();
    const [paths, setPaths] = useState([]);
    const [faqData, setFaqData] = useState([]);
    const [fHomeWhyGetData, setfHomeWhyGetData] = useState([]);
    const [fHomeOurServicesSection, setfHomeOurServicesSection] = useState([]);
    const [fHomeAllOurServicesData, setfHomeAllOurServicesData] = useState([]);
    const [fHomeCallBackData, setfHomeCallBackData] = useState({});
    const [fHomeProjectData, setfHomeProjectData] = useState([]);
    const [fHeadingImagesData, setfHeadingImagesData] = useState({});
    const [aboutUsData, setAboutUsData] = useState({});
    const [fContactUsValuesData, setfContactUsValuesData] = useState({});
    const [fTeamData, setTeams] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [experts, setExperts] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [fHomeSliderData, setHomeSliderData] = useState([]);

    // Function to fetch paths from your API
    // const fetchPaths = async () => {
    //     try {
    //         const response = await fetch('http://api.ihex.it:8083/api/v1/getweb/getWebsite/getPath');
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         const data = await response.json();
    //         console.log('Fetched data:', data); // Log the entire response
    //         return data.paths; // Adjust based on your API response structure
    //     } catch (error) {
    //         console.error('Error fetching paths:', error);
    //         return [];
    //     }
    // };

    // useEffect(() => {
    //     const loadPaths = async () => {
    //         const fetchedPaths = await fetchPaths();
    //         console.log('Fetched paths:', fetchedPaths); // Log the fetched paths
    //         setPaths(fetchedPaths || []);
    //     };

    //     loadPaths();
    // }, []);

    // const fetchData = async (fetchFunction, setter) => {
    //     try {
    //         const response = await fetchFunction();
    //         const result = response.result;
    //         setter(result);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    useEffect(() => {
        // if (paths.includes(location.pathname)) {
        //     fetchData(fHeadingImages, setfHeadingImagesData);
        //     fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);
        //     fetchData(fHomeCallBack, setfHomeCallBackData);
        //     fetchData(fExperts, setExperts);
        // }
        //         if (location.pathname.length > 0 && location.pathname.includes(location.pathname)) {
        //     fetchData(fHeadingImages, setfHeadingImagesData);
        //     fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);
        //     fetchData(fHomeCallBack, setfHomeCallBackData);
        //     fetchData(fExperts, setExperts);
        // }
        if (location.pathname === '/services') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);
            fetchData(fHomeCallBack, setfHomeCallBackData);
            fetchData(fExperts, setExperts);
        }

        if (location.pathname === '/service') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);
            fetchData(fHomeCallBack, setfHomeCallBackData);
            fetchData(fExperts, setExperts);
        }



        if (location.pathname === '/about-us') {
            fetchData(fAboutUs, setAboutUsData);
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fTestimonials, setTestimonials);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);
            fetchData(fHomeOurServices, setfHomeOurServicesSection);
            fetchData(fExperts, setExperts);
        }
        if (location.pathname === '/') {
            fetchData(fHomeWhyGet, setfHomeWhyGetData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);
            fetchData(fHomeOurServices, setfHomeOurServicesSection);
            fetchData(fHomeCallBack, setfHomeCallBackData);
            fetchData(fHomeProject, setfHomeProjectData);
            fetchData(fSponsors, setSponsors);
            fetchData(fExperts, setExperts);
            fetchData(fHomeSlider, setHomeSliderData);
            fetchData(fTestimonials, setTestimonials);
        }
        if (location.pathname === '/faq') {            
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fFaqsDetails, setFaqData);
        }
        if (location.pathname === '/contact') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fContactUsValues, setfContactUsValuesData);
        }
        if (location.pathname === '/team') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fTeams, setTeams);
         }

        if (location.pathname === '/service/custom-software-solutions') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);

         }
        if (location.pathname === '/service/network-level-solutions') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);

         }
        if (location.pathname === '/service/fintech-services') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);

         }
        if (location.pathname === '/service/IT-and-infrastructure-solutions') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);

         }
        if (location.pathname === '/service/research-and-analysis') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);

         }
        if (location.pathname === '/service/consultancy-services') {
            fetchData(fHeadingImages, setfHeadingImagesData);
            fetchData(fHomeAllOurServices, setfHomeAllOurServicesData);

         }
         if (location.pathname === '/cookie-policy') {
            fetchData(fHeadingImages, setfHeadingImagesData);

         }
         if (location.pathname === '/terms-and-conditions') {
            fetchData(fHeadingImages, setfHeadingImagesData);

         }
    }, [location.pathname]);

    return (
        <DataContext.Provider
            value={{
                faqData,
                fHomeWhyGetData,
                fHomeAllOurServicesData,
                fHomeOurServicesSection,
                fHomeCallBackData,
                fHomeProjectData,
                fHeadingImagesData,
                aboutUsData,
                fContactUsValuesData,
                fTeamData,
                testimonials,
                experts,
                sponsors,
                fHomeSliderData
            }}
        >
            {children}
        </DataContext.Provider>
    );
};
