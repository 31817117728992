/* eslint-disable */

import React, { useEffect, useState } from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../images/favicon.ico";
import "../../../css/animate.css";
import { useData } from "../../../context/Provider";
// eslint-disable-next-line no-unused-vars
import { useParams, useNavigate } from "react-router-dom";
import DynamicMetaTags from "../../../components/DynamicMetaTags";
const AllServicesDetails = () => {
    const { Title, Page_url } = useParams();
    const navigate = useNavigate();
    const { fHomeAllOurServicesData } = useData();

    const [serviceTitle, setServiceTitle] = useState(Page_url || '');
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!Page_url && fHomeAllOurServicesData && fHomeAllOurServicesData.length > 0) {
            setServiceTitle(fHomeAllOurServicesData[0].Page_url);
        } else {
            setServiceTitle(Page_url);
        }
    }, [Page_url, fHomeAllOurServicesData]);

    // console.log(Page_url, "Page_url", data, "serviceTitle", serviceTitle);


    useEffect(() => {
        if (serviceTitle && fHomeAllOurServicesData) {
            const fetchedData = fHomeAllOurServicesData.find((item) => item.Page_url === serviceTitle);
            setData(fetchedData);
        }
    }, [serviceTitle, fHomeAllOurServicesData]);

    const handleClick = (pageUrl) => {
        event.preventDefault();

        setServiceTitle(pageUrl);
        navigate(`/service/${pageUrl}`);
        <DynamicMetaTags page={Page_url} />
        window.location.reload();
    };
    const [page, setPage] = useState(null);

    useEffect(() => {
        const currentUrl = window.location.href;
        var path = window.location.pathname;
        var parts = path.split('/');
        var filename = parts[parts.length - 1];
        const checkurl = '/service/' + filename;
        if (checkurl === window.location.pathname) {
            setPage(filename);
        }
        else {
            setPage(filename);
        }

    }, []);

    return (
        <>

            <section className="service">
                {/* {console.log('on services - url ', window.location.pathname)} */}

                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-xs-12 service-colmun">
                            <div className="sidebar">
                                <ul className="sidebar-list">
                                    {page && <DynamicMetaTags page={page} />}
                                    {fHomeAllOurServicesData.map((item, index) => (

                                        <li key={index}>

                                            <a
                                                className={item.Page_url === serviceTitle ? "active" : ""}
                                                onClick={() => handleClick(item.Page_url)}
                                            >
                                                {item.Title}
                                            </a>

                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12 service-colmun">
                            <div className="sidebar-details">
                                <div dangerouslySetInnerHTML={{ __html: data?.TextEditor }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AllServicesDetails;