// import React, { useState } from "react";
// import "../../css/style.css";
// import "../../css/responsive.css";
// import "../../css/custom/theme-2.css";
// import "../../images/favicon.ico"
// import "../../css/animate.css";
// import { getAQuoteData } from "../../context/api";
// import styled from 'styled-components';
// import { useData } from "../../context/Provider";

// const API_BASE_URL = process.env.REACT_APP_API_URL;
// const CallBack = () => {
//     const {fHomeCallBackData}= useData();
//     const [formData, setFormData] = useState({
//         Name: "",
//         Email: "",
//         Phone: "",
//         Type: "",
//     })
//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             if (!formData.Name) {
//                 alert("Name is required.");
//                 return;
//             }

//             if (!formData.Email) {
//                 alert("Email is required.");
//                 return;
//             }

//             if (!formData.Phone) {
//                 alert("Phone is required.");
//                 return;
//             }
//             const response = await getAQuoteData(formData)
//             alert(response.message)
//             setFormData({
//                 Name: "",
//                 Email: "",
//                 Phone: "",
//                 Type: "",
//             })
//         } catch (error) {
//             console.log(error);
//         }
//     }
//   //  console.log(fHomeCallBackData);
//   //  console.log(`${API_BASE_URL}/home/${fHomeCallBackData?.Banner_image}`);

//     const CallBackSection = styled.section`
//   position: relative;
//   width: 100%;
//   background-size: cover;
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-image: ${({ imageUrl }) => `url(${imageUrl})`};
//   height:522px;

  
//   &:before {
//     position: absolute;
//     content: '';
//     width: 36%;
//     height: 100%;
//     top: 0;
//     right: 0;
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center center;
//     background-image: ${({ overlayImageUrl }) => `url(${overlayImageUrl})`};
//   }
//   $:after{
//     position: absolute;
//     content: '';
//     background: #253559;
//     width: 64%;
//     height: 100%;
//     left: 0px;
//     top: 0px;
//     opacity: .9;
//   }
// `;


//     return (
//         <>
           

//             <CallBackSection
//             className="call-back sec-pad" 
//                 id="get-a-quote-section"
//                 imageUrl={`${API_BASE_URL}/home/${fHomeCallBackData?.Banner_image}`}
//                 overlayImageUrl={`${API_BASE_URL}/home/${fHomeCallBackData?.Image}`}
//             >
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-8 col-sm-12 col-xs-12 colmun">
//                             <div className="call-back-content">
//                                 <div className="call-back-title">
//                                     <div className="section-title"><h2>Request A <span>Call Back</span></h2></div>
//                                     <div className="title-text"><p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusm temp </p></div>
//                                 </div>
//                                 <div className="form-area">
//                                     <form onSubmit={handleSubmit} >
//                                         <div className="row">
//                                             <div className="col-md-6 col-sm-6 col-xs-12 colmun">
//                                                 <input type="text" placeholder="Your Name" required
//                                                     name="Name" value={formData.Name} onChange={handleChange} />
//                                             </div>
//                                             <div className="col-md-6 col-sm-6 col-xs-12 colmun">
//                                                 <input type="email" placeholder="Email" required
//                                                     name="Email" value={formData.Email} onChange={handleChange} />
//                                             </div>
//                                             <div className="col-md-6 col-sm-6 col-xs-12 colmun">
//                                                 <input type="text" placeholder="Phone" required
//                                                     name="Phone" value={formData.Phone} onChange={handleChange} />
//                                             </div>
//                                             <div className="col-md-6 col-sm-6 col-xs-12 colmun">
//                                                 <div className="select-box">
//                                                     {/* <i className="fa fa-angle-down"></i> */}
//                                                     <select className="custom-select-box" name="Type" value={formData.Type}
//                                                         onChange={handleChange}>
//                                                         <option hidden>Select a Topic</option>
//                                                         <option value="Investor Relations">Investor Relations</option>
//                                                         <option value="Merchant Relations">Merchant Relations</option>
//                                                         <option value="Feedback">Feedback</option>
//                                                         <option value="Get Appointment">Get Appointment</option>

//                                                     </select> 
//                                                  </div>
//                                             </div>
//                                         </div>
//                                         <div className="button">
//                                             <button type="submit" className="btn-one style-one radi" >Send Request</button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </CallBackSection>
//         </>
//     )
// }
// export default CallBack 


import React, { useState } from "react";
import styled from 'styled-components';
import { useData } from "../../context/Provider";
import { getAQuoteData } from "../../context/api";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const CallBack = () => {
    const { fHomeCallBackData } = useData();
    const [formData, setFormData] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Type: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // Focus on the input element after every character typed
        e.target.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!formData.Name || !formData.Email || !formData.Phone) {
                alert("Please fill in all required fields.");
                return;
            }
            const response = await getAQuoteData(formData);
            alert(response.message);
            setFormData({
                Name: "",
                Email: "",
                Phone: "",
                Type: "",
            });
        } catch (error) {
            console.log(error);
        }
    };

    const CallBackSection = styled.section`
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: ${({ imageUrl }) => `url(${imageUrl})`};
        height: 522px;
        
        &:before {
            position: absolute;
            content: '';
            width: 36%;
            height: 100%;
            top: 0;
            right: 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            background-image: ${({ overlayImageUrl }) => `url(${overlayImageUrl})`};
        }

        &:after {
            position: absolute;
            content: '';
            background: #253559;
            width: 64%;
            height: 100%;
            left: 0px;
            top: 0px;
            opacity: .9;
        }
    `;

    return (
        <CallBackSection
            className="call-back sec-pad"
            id="get-a-quote-section"
            imageUrl={`${API_BASE_URL}/home/${fHomeCallBackData?.Banner_image}`}
            overlayImageUrl={`${API_BASE_URL}/home/${fHomeCallBackData?.Image}`}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-12 col-xs-12 colmun">
                        <div className="call-back-content">
                            <div className="call-back-title">
                                <div className="section-title"><h2>Request A <span>Call Back</span></h2></div>
                                <div className="title-text"><p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusm temp </p></div>
                            </div>
                            <div className="form-area">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                                            <input
                                                type="text"
                                                placeholder="Your Name"
                                                required
                                                name="Name"
                                                value={formData.Name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                required
                                                name="Email"
                                                value={formData.Email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                                            <input
                                                type="text"
                                                placeholder="Phone"
                                                required
                                                name="Phone"
                                                value={formData.Phone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-xs-12 colmun">
                                            <div className="select-box">
                                                <select
                                                    className="custom-select-box"
                                                    name="Type"
                                                    value={formData.Type}
                                                    onChange={handleChange}
                                                >
                                                    <option hidden>Select a Topic</option>
                                                    <option value="Investor Relations">Investor Relations</option>
                                                    <option value="Merchant Relations">Merchant Relations</option>
                                                    <option value="Feedback">Feedback</option>
                                                    <option value="Get Appointment">Get Appointment</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button">
                                        <button type="submit" className="btn-one style-one radi" >Send Request</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CallBackSection>
    );
};

export default CallBack;
