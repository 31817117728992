import React, { useState } from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/custom/theme-2.css";
import "../../css/animate.css";
// import GoogleMap from "./Googlemap";
import { PContactUsEmail } from "../../context/api";
// import GoogleMapPage from "./GoogleMapPackage";
import OpenStreetMap from "./OpenSpreetMap";

const ContactForm = () => {

    const [formData, setFormData] = useState({
        Name:"",
        Email:"",
        Phone:"",
        Subject:"",
        Message:"",
    })
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async (e) =>{
        e.preventDefault();
    try {

        
        if (!formData.Name) {
            alert("Name is required.");
            return;
        }

        if (!formData.Email) {
            alert("Email is required.");
            return;
        }

        if (!formData.Phone) {
            alert("Phone is required.");
            return;
        }
        if (!formData.Subject) {
            alert("Phone is required.");
            return;
        }
        const response = await PContactUsEmail(formData)
        alert(response.message)
        setFormData({
            Name:"",
            Email:"",
            Phone:"",
            Subject:"",
            Message:"",
        })
    } catch (error) {
        // aj // console.log(error);
    }}
    return (
        <>
           
    <section className="contact-section contact-page">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12 contact-colmun">
                    <div className="contact-form-area">
                        <div className="title"><h2>Get in Touch</h2></div>
                        <div className="text"><p>Have something on your mind? Let's talk! Contact us for any information or support.</p></div>
                        <form className="default-form" >
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <input type="text" name="Name"  placeholder="Name" required=""
                                    value={formData.Name} onChange={handleChange}/>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <input type="email" name="Email"  placeholder="Email"
                                     required=""  value={formData.Email} onChange={handleChange}/>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <input type="text" name="Phone"  placeholder="Phone" required=""
                                    value={formData.Phone} onChange={handleChange}/>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <input type="texxt" name="Subject"  placeholder='Subject' required=""
                                    value={formData.Subject} onChange={handleChange}/>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <textarea placeholder="Message" name="Message" required=""
                                    value={formData.Message} onChange={handleChange}/>
                                </div>
                            </div>
                            <button type="submit" className="btn-one style-one radi"
                            onClick={handleSubmit}
                            //  data-loading-text="Please wait..."
                             >Send Message</button>
                        </form>
                    </div>
                </div>
                
                <div className="col-md-6 col-sm-12 col-xs-12 contact-colmun">
                    <div className="google-map-area">
                        {/* <div 
                            className="google-map" 
                            id="contact-google-map" 
                            data-map-lat="41.641264" 
                            data-map-lng="19.705595" 
                            data-icon-path="images/resources/map-marker.png" 
                            data-map-title="Brooklyn, New York, United Kingdom" 
                            data-map-zoom="12" 
                            data-markers='{
                                "marker-1": [41.641264, 19.705595, "<h4>Branch Office</h4><p>77/99 London UK</p>","images/resources/map-marker.png"]
                            }'>   */}

                        {/* </div> */}
                        {/* <GoogleMap/>  */}
                        <OpenStreetMap />
                         {/* <GoogleMapPage/> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
        </div>
    </section>
        </>
    )
}
export default ContactForm
