// import React from "react";
// import "../../css/style.css";
// import "../../css/responsive.css";
// import "../../css/custom/theme-2.css";
// import "../../css/animate.css";
// import Preloader from "../../shares/Preloader";
// import TitleBread from "../../shares/TitleAndBreadCrumb";
// import ContactForm from "./ContactForm";
// import { useData } from "../../context/Provider";
// import DynamicMetaTags from "../../components/DynamicMetaTags";

// const Contact = () => {
//     const {fContactUsValuesData, fHeadingImagesData} = useData()
//     const Title = "Contact Us";
//     const breadTitle = "Contact Us";
//     const API_BASE_URL = process.env.REACT_APP_API_URL;

//     let location = fContactUsValuesData?.Location || "123 Main St, Suite 400, Anytown, USA";
    
//     // Split the location string at commas
//     let parts = location.split(',');
    
//     // Check if there are at least three parts
//     if (parts.length >= 3) {
//       // Join the first two parts together for the first line, and keep the third part as the third line
//       let formattedLocation = `${parts[0].trim()},\n${parts[1].trim()},\n${parts.slice(2).join(',').trim()}`;
//       // aj // console.log(formattedLocation);
//     } else {
//       // Fallback in case the string doesn't split into enough parts
//       let fallbackLocation = location.replace(/,/g, ',\n');
//       // aj // console.log(fallbackLocation);
//     }

//     const address = fallbackLocation;




//     return (
//         <>


//             <Preloader />
//             <DynamicMetaTags page="contact"/>
//             {/* <TitleBread 
//             // Title={Title} 
//             breadTitle={breadTitle} 
//             image={fHeadingImagesData.ContactUs_Image} /> */}

// <div className="contact-bg centered" style={{ backgroundImage: `url(${`${API_BASE_URL}/assets/${fHeadingImagesData?.ContactUs_Image}`})` }}>
//                 {/* <div className="container">
//                     <div className="text">Service Details</div> 
//                 </div>  */}
//         </div> 
//             <div className="bread-crumb">
//             <div className="container">
//               <div className="text"><a href="/">Home</a><i className="fa fa-angle-right"></i> Our Team
//                 <div className="share"><i className="fa fa-share-alt" aria-hidden="true"></i>Share</div>
//               </div>
//             </div>
//           </div>
//             <section className="contact-info contact-page centered">
                
//                 <div className="container">

//                     <div className="row">
//                         <div className="col-md-4 col-sm-6 col-xs-12 contact-colmun" style={{height:'300px'}}>
//                             <div className="single-item hvr-float-shadow">
//                                 <div className="icon-box"><i className="fa fa-map-marker"></i></div>
//                                 <div className="title">Location</div>
//                                 <div className="text"><p>{address}
//                                      </p>
//                                     </div>
//                             </div>
//                         </div>
//                         <div className="col-md-4 col-sm-6 col-xs-12 contact-colmun">
//                             <div className="single-item hvr-float-shadow">
//                                 <div className="icon-box"><i className="fa fa-phone"></i></div>
//                                 <div className="title">Phone</div>
//                                 <div className="text"><p>{fContactUsValuesData?.Phone_1}<br /> {fContactUsValuesData?.Phone_2}</p></div>
//                             </div>
//                         </div>
//                         <div className="col-md-4 col-sm-6 col-xs-12 contact-colmun">
//                             <div className="single-item hvr-float-shadow">
//                                 <div className="icon-box"><i className="fa fa-envelope"></i></div>
//                                 <div className="title">Email</div>
//                                 <div className="text"><p><a href="#">{fContactUsValuesData?.Email_1}</a><br /><a href="#">{fContactUsValuesData?.Email_2}</a></p></div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <ContactForm/>
//         </>
//     )
// }
// export default Contact




import React from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/custom/theme-2.css";
import "../../css/animate.css";
import Preloader from "../../shares/Preloader";
import TitleBread from "../../shares/TitleAndBreadCrumb";
import ContactForm from "./ContactForm";
import { useData } from "../../context/Provider";
import DynamicMetaTags from "../../components/DynamicMetaTags";

const Contact = () => {
    const { fContactUsValuesData, fHeadingImagesData } = useData();
    const Title = "Contact Us";
    const breadTitle = "Contact Us";
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    // let location = fContactUsValuesData?.Location || "123 Main St, Suite 400, Anytown, USA";
    
    // // Split the location string at commas
    // let parts = location.split(',');
    // let formattedLocation;

    // // Check if there are at least three parts
    // if (parts.length >= 3) {
    //     // Join the first two parts together for the first line, and keep the third part as the third line
    //     formattedLocation = `${parts[0].trim()},\n${parts[1].trim()},\n${parts.slice(2).join(',').trim()}`;
    // } else {
    //     // Fallback in case the string doesn't split into enough parts
    //     formattedLocation = location.replace(/,/g, ',\n');
    // }

    // const address = formattedLocation;
    // // aj // console.log(address);

    return (
        <>
            <Preloader />
            <DynamicMetaTags page="contact" />
            {/* <TitleBread 
            // Title={Title} 
            breadTitle={breadTitle} 
            image={fHeadingImagesData.ContactUs_Image} /> */}
      {/* <div className="about-bg centered" style={{   backgroundImage: fHeadingImagesData?.About_Image? `url(${`${API_BASE_URL}/assets/${fHeadingImagesData?.ContactUs_Image}`})` :'' }}> */}

            <div className="contact-bg centered" style={{ backgroundImage:  fHeadingImagesData?.ContactUs_Image? `url(${`${API_BASE_URL}/assets/${fHeadingImagesData?.ContactUs_Image}`})` :'' }}>
                {/* <div className="container">
                    <div className="text">Service Details</div> 
                </div>  */}
            </div> 
            <div className="bread-crumb">
                <div className="container">
                    <div className="text">
                        <a href="/">Home</a>
                        <i className="fa fa-angle-right"></i> Contact Us
                        {/* <div className="share"><i className="fa fa-share-alt" aria-hidden="true"></i>Share</div> */}
                    </div>
                </div>
            </div>
            <section className="contact-info contact-page centered">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-12 contact-colmun" style={{ height: '300px' }}>
                            <div className="single-item hvr-float-shadow">
                                <div className="icon-box"><i className="fa fa-building"></i></div>
                                <div className="title">Location</div>
                                <div className="text"><p>{ fContactUsValuesData?.Location }</p></div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 contact-colmun">
                            <div className="single-item hvr-float-shadow">
                                <div className="icon-box"><i className="fa fa-phone"></i></div>
                                <div className="title">Phone</div>
                                <div className="text"><p>{fContactUsValuesData?.Phone_1}<br /> {fContactUsValuesData?.Phone_2}</p></div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 contact-colmun">
                            <div className="single-item hvr-float-shadow">
                                <div className="icon-box"><i className="fa fa-envelope"></i></div>
                                <div className="title">Email</div>
                                <div className="text"><p><a href="#">{fContactUsValuesData?.Email_1}</a><br /><a href="#">{fContactUsValuesData?.Email_2}</a></p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactForm />
        </>
    );
}

export default Contact;
