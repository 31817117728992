import React, { useEffect, useState } from "react";

import "../../css/style.css";
import "../../css/responsive.css";
import "../../images/favicon.ico";
import "../../css/animate.css";
import { Link, useNavigate } from "react-router-dom";
import Home from "../Home/Home";
import AboutUs from "../AboutsUs/Index";
import OurServices from "../Services/OurServices/OurServices";
import OurServicesDetailsWithId from "../Services/OurServicesDetails/ServicesDetailsWithId";
import OurServicesDetails from "../Services/OurServicesDetails/Index";
import Team from "../UiPages/OurTeam";
import Faqs from "../UiPages/Faqs";
import Contact from "../Contact";
import logo from "../../images/logo/getlogo_black.png"
 import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const NavBar = () => {
    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    const [colorSetting, setColorSetting] = useState(false)
    const [currentLink, setCurrentLink] = useState('/');
    const [isNavbarToggle, setIsNavbarToggle] = useState(false)
    const [isNavbarToggleSticky, setIsNavbarToggleSticky] = useState(false)
    const [isSubMenu, setIsSubMenu] = useState(false)
 

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            setIsScrolled(scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerClasses = `header-lower  ${isScrolled ? 'header-lower-custom' : ''}`;

    const handleOpenClose = () => {
        setColorSetting(!colorSetting)
    }
    const handleColorChange = async (theme) => {
        await import(`../../css/custom/${theme}`);
    };

    useEffect(() => {
        const path = window.location.pathname;
        setCurrentLink(path);

    }, [])

    useEffect(() => {
        const path = window.location.pathname;
        setCurrentLink(path);
        handleColorChange('theme-5.css'); // Set default RED
    }, [])

    const routes = [
        { path: '/', element: <Home /> },
        { path: '/about', element: <AboutUs /> },
        { path: '/services', element: <OurServices /> },
        { path: '/service/:id?', element: <OurServicesDetailsWithId /> },
        { path: '/service', element: <OurServicesDetails /> },
        { path: '/team', element: <Team /> },
        { path: '/faq', element: <Faqs /> },
        { path: '/contact', element: <Contact /> },
    ];

    // const generateSitemapXML = () => {
    //     const currentDate = new Date().toISOString().split('T')[0];

    //     return `
    //       <?xml version="1.0" encoding="UTF-8"?>
    //       <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    //         <url>
    //           <loc>https://yourdomain.com/</loc>
    //           <lastmod>${currentDate}</lastmod>
    //           <changefreq>monthly</changefreq>
    //           <priority>1.0</priority>
    //         </url>
    //         ${generateRoutesXML()}
    //       </urlset>
    //     `;
    // };

    // const generateRoutesXML = () => {
    //     return routes
    //         .map((route) => {
    //             return `
    //           <url>
    //             <loc>http://localhost/${route.path}</loc>
    //             <lastmod>${new Date().toISOString().split('T')[0]}</lastmod>
    //             <changefreq>monthly</changefreq>
    //             <priority>0.8</priority>
    //           </url>
    //         `;
    //         })
    //         .join('');
    // };

    // const handleDownload = () => {
    //     const sitemapContent = generateSitemapXML();

    //     const blob = new Blob([sitemapContent], { type: 'application/xml' });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'sitemap.xml';
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     window.URL.revokeObjectURL(url);
    // };

    const handleToggleNavbar = () => {
        setIsNavbarToggle(!isNavbarToggle)
    }
    const handleToggleSticky = () => {
        setIsNavbarToggleSticky(!isNavbarToggleSticky)
    }

    const hadleSubmenu = () => {
        setIsSubMenu(!isSubMenu)
    }

    function scrollToGetAQuote() {
        const getQuoteSection = document.getElementById('get-a-quote-section');
        if (getQuoteSection) {
            getQuoteSection.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleNavigation = (item) => {
        navigate(item)
    }

    return (
        <>
            {/* <!-- switcher menu --> */}
            {/* <div className="switcher"> */}

                {/* <!-- Switcher button --> */}
                {/* <div className="switch_btn" onClick={handleOpenClose}>
                    <button><i className="fa fa-cog fa-spin"></i></button>
                </div> 
                disabled to hide settings button and set red as default 
                */}
                

                {/* <!-- switcher body --> */}
                {/* <div className={`switch_menu ${colorSetting ? "swicth_menu_custom" : ""}`}>
                    <h5 className="title">Style Switchers</h5> */}


                    {/* <!-- color changer --> */}
                    {/* <div className="switcher_container">
                        <h5>Color Skins</h5>
                        <ul id="styleOptions" title="switch styling">
                            <li>
                                <a href="javascript:void(0)" onClick={() => handleColorChange('theme-5.css')} className="color5"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={() => handleColorChange('theme-3.css')} className="color3"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={() => handleColorChange('theme-4.css')} className="color4"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={() => handleColorChange('theme-2.css')} className="color2"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={() => handleColorChange('theme-6.css')} className="color6"></a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div> */}
            {/* <!-- end switcher menu --> */}

            {/* <!--header search--> */}
            {/* <section className="header-search">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="search-form pull-right">
                                <form action="#">
                                    <div className="search">
                                        <input type="search" name="search" value="" placeholder="Search Something" />
                                        <button type="submit"><span className="fa fa-search" aria-hidden="true"></span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!--end header search--> */}

            {/* <!-- main header area --> */}
            <header className="main-header">

                {/* <!-- header upper --> */}
                <div className="header-upper">
                    <div className="container">
                        <div className="top-right">
                            <ul className="social-top">
                                <li><a href="https://www.facebook.com/getransfer"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                <li><a href="https://x.com/GeTrx2104"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                <li><a href="/sitemap.xml"><FontAwesomeIcon icon={faRssSquare} /></a></li>
                                {/* <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li onClick={handleDownload}><a href="#"><i className="fa fa-rss"></i></a></li> */}
                            </ul>
                            <div className="button-top">
                                {/* <a href="/contact" className="btn-one style-one">Get a Quote</a> */}
                                <Link to="/" className="btn-one style-one radi" onClick={scrollToGetAQuote}>Get A Quote</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={headerClasses}
                //  className="header-lower header-lower-custom"
                // classNam="sticky-header-custom"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="logo-continer">
                                    <img src={logo} alt="logo" onClick={() => handleNavigation("/")} />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12">
                                <div className="menu-bar">
                                    <nav className="main-menu">
                                        <div className="navbar-header" onClick={handleToggleNavbar}>
                                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                            </button>
                                        </div>
                                        <div className={`navbar-collapse collapse clearfix ${isNavbarToggle ? "collapse_navbar_open" : ""}`}>
                                            <ul className="navigation clearfix">
                                                <li onClick={() => handleNavigation("/")} className={currentLink === '/' ? 'current' : ''}>
                                                    <a href="/">Home</a>
                                                </li>
                                                <li onClick={() => handleNavigation("/about-us")} className={currentLink === '/about-us' ? 'current' : ''}>
                                                    <a href="/about-us">About Us</a>
                                                </li>

                                                <li onClick={() => handleNavigation("/services")}
                                                    className={currentLink === '/services' || currentLink === '/service' ? 'current dropdown' : 'dropdown'}
                                                ><a href="/services" onClick={hadleSubmenu}>Services</a>
                                                    <ul className={`sub_menu ${isSubMenu ? "SubMenu_open" : ""}`}>
                                                        <li onClick={() => handleNavigation("/services")} ><a href="/services"  >Our Service</a></li>
                                                        <li onClick={() => handleNavigation("/service")}><a href="/service" onClick={() => handleNavigation("/service")}>Service Details</a></li>
                                                    </ul>
                                                </li>
                                                {/* <li
                                                    onClick={() => handleNavigation("/faq")} className={currentLink === '/faq' ? 'current' : ''}
                                                >
                                                    <a href="/faq">FAQ's</a>
                                                </li> */}

                                                <li
                                                    onClick={() => handleNavigation("/team")} className={currentLink === '/team' ? 'current' : ''}
                                                >
                                                    <a href="/team">Our Team</a>
                                                </li>

                                                <li
                                                    onClick={() => handleNavigation("/contact")} className={currentLink === '/contact' ? 'current' : ''}
                                                >
                                                    <a href="/contact" >Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end header lower --> */}

                {/* <!--sticky header--> */}
                <div
                    className={`sticky-header ${isScrolled ? "sticky-header-custom" : ""}`}
                //   className="sticky-header sticky-header-custom"
                >
                    <div className="container clearfix">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 col-xs-12">
                                <div className="logo-continer">
                                    <img src={logo} alt="logo" onClick={() => handleNavigation("/")} href="/" />
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-12 col-xs-12">
                                <div className="menu-bar">
                                    <nav className="main-menu">
                                        <div className="navbar-header" onClick={handleToggleSticky}>
                                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                            </button>
                                        </div>
                                        <div className={`navbar-collapse collapse clearfix ${isNavbarToggleSticky ? "collapse_navbar_open" : ""}`}>
                                            <ul className="navigation clearfix">
                                                <li onClick={() => handleNavigation("/")} className={currentLink === '/' ? 'current' : ''}><a href="/" >Home</a>
                                                </li>
                                                <li className={currentLink === '/about-us' ? 'current' : ''}><a onClick={() => handleNavigation("/about-us")}  >About Us</a>
                                                </li>
                                                <li onClick={() => handleNavigation("/services")} className={currentLink === '/services' || currentLink === '/service' ? 'current dropdown' : 'dropdown'}><a href="/services" onClick={hadleSubmenu} >Services</a>
                                                    <ul className={`sub_menu ${isSubMenu ? "SubMenu_open" : ""}`}>
                                                        <li onClick={() => handleNavigation("/services")}><a href="/services"  >Our Service</a></li>
                                                        <li onClick={() => handleNavigation("/service")}><a href="/service">Service Details</a></li>
                                                    </ul>
                                                </li>
                                                {/* <li
                                                    onClick={() => handleNavigation("/faq")} className={currentLink === '/faq' ? 'current' : ''}
                                                >
                                                    <a href="/faq"  >FAQ's</a>
                                                </li> */}
                                                <li
                                                    onClick={() => handleNavigation("/team")} className={currentLink === '/team' ? 'current' : ''}
                                                >
                                                    <a href="/team" >Our Team</a>
                                                </li>
                                                <li onClick={() => handleNavigation("/contact")} className={currentLink === '/contact' ? 'current' : ''}>
                                                    <a href="/contact" >Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                    <div className="info-box">
                                        <Link to="/" className="btn-one style-one radi" onClick={scrollToGetAQuote}>Get A Quote</Link>
                                        {/* <div className="button"><a href="#get-a-quote-section" className="btn-one style-one radi">Get A Quote</a></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end sticky header --> */}
            </header>
        </>
    )
}

export default NavBar;