import React from "react";
import featureImage from "../../images/feature/1.jpg"
import featureImage2 from "../../images/feature/2.jpg"
import featureImage3 from "../../images/feature/3.jpg"

const API_BASE_URL = process.env.REACT_APP_API_URL;
const Features = ({ data }) => {
    return (
        <section className="feature-section sec-pad">
            <div className="container">
                <div className="feature-title centered">
                    <div className="section-title"><h2>WHY GETRANSFER</h2></div>
                    <div className="title-text"><p>GETransfer stands out for our commitment to excellence, innovative solutions, and dedicated support</p></div>
                </div>
                <div className="row">

                    {/* {data?.slice(0, 3).map((item, index) => ( */}
                    {data?.map((item, index) => (
                        <div className="col-md-4 col-sm-6 col-xs-12 feature-colmun" key={index} style={{ paddingTop: "30px" }}>
                            <div className="single-item">
                                <div className="img-box"><figure><img src={`${API_BASE_URL}/feature/${item.Image}`} alt="" /></figure></div>
                                <div className="single-content">
                                    <div className="icon-box">

                                        {/* <img
                        src={`${API_BASE_URL}/ourServices/${item.Icon}`}
                        alt={`Sponsor ${index}`}
                      /> */}

                                        {/* <i className="flaticon-graphic"></i> */}
                                        {/* {console.log(item.IconBox)} */}
                                        <i className={item.IconBox}></i>

                                    </div>
                                    <h4>{item.Title}</h4>
                                    <div className="text" style={{ height: '155px' }}><p>{item.Description.length > 300 ? `${item.Description.substring(0, 300)}...` : item.Description}</p></div>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default Features