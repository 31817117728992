import React from "react";
import "../../../css/style.css";
import "../../../css/responsive.css";
import "../../../css/custom/theme-2.css";
import "../../../css/animate.css";
import AllServicesDetails from "./AllServices";
import Preloader from "../../../shares/Preloader";
import { useData } from "../../../context/Provider";
import { useParams } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const OurServicesDetailsWithId = () => {
    const {
        //fHomeAllOurServicesData,
        fHeadingImagesData } = useData()
    const { id, Page_url } = useParams();
    //    console.log(id, "id");
    // const findData = fHomeAllOurServicesData.find((item) => item.id === id)
    return (
        <>
            <Preloader />
            <div className="serviceDetails-bg centered"
                style={{ backgroundImage: `url(${`${API_BASE_URL}/assets/${fHeadingImagesData?.OurServicesDetails_Image}`})` }}>
                <div className="container">
                    {/* <div className="text">Service Details</div> */}
                </div>
            </div>

            <div className="bread-crumb">
                <div className="container">
                    <div className="text"><a href="/">Home</a><i className="fa fa-angle-right"></i> Service Details
                        {/* <div className="share"><i className="fa fa-share-alt" aria-hidden="true"></i>Share</div> */}

                    </div>
                </div>
            </div>
            <AllServicesDetails id={id} />
        </>
    )
}
export default OurServicesDetailsWithId