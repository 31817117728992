import React from "react";
import { BrowserRouter, Route,  Routes,Switch } from "react-router-dom";
import Home from "../pages/Home/Home";
import Footer from "../pages/Footer/Footer";
import NavBar from "../pages/Navbar/Navbar";
import AboutUs from "../pages/AboutsUs/Index";
import OurServices from "../pages/Services/OurServices/OurServices";
import OurServicesDetails from "../pages/Services/OurServicesDetails/Index";
import Team from "../pages/UiPages/OurTeam";
import Faqs from "../pages/UiPages/Faqs";
import Contact from "../pages/Contact";
import Error from "../pages/Error/Index";
import OurServicesDetailsWithId from "../pages/Services/OurServicesDetails/ServicesDetailsWithId";
import Sitemap from "../pages/SiteMap/SiteMap";
import TermsAndConditions from "../pages/Policy/TermsAndConditions";
import CookiePolicyContnent from "../pages/Cookies";
import ScrollToTop from "../components/ScrollToTop";

const PageRoutes = () => {
    return (
        <>
            {/* <BrowserRouter> */}
            <NavBar/>
            <ScrollToTop />
                <Routes>
               

                    <Route exact path="/" element={<Home />} />
                    <Route path="/about-us" element={<AboutUs/>}/>
                    <Route path="/services" element={<OurServices/>}/>
                    {/* <Route path="/service/:id?/:Title?" element={<OurServicesDetailsWithId/>}/> */}
                   
                    <Route path="/service/:Page_url?" element={<OurServicesDetailsWithId/>}/>
                    {/* <Route path="/service" element={<OurServicesDetails />}/> */}
                    <Route path="/service" element={<OurServicesDetails />}/>

                    <Route path="/team" element={<Team/>}/>
                    <Route path="/faq" element={<Faqs/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/cookie-policy" element={<CookiePolicyContnent/>}/>
                    <Route path="/sitemap.xml" element={<Sitemap/>}/>
                    <Route path="*" element={<Error/>}/>
                </Routes>
                <Footer/>
            {/* </BrowserRouter> */}
        </>
    )
}
export default PageRoutes