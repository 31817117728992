import React from "react";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/animate.css";
import "../../images/favicon.ico";

import CustomSlider from "./CustomSlider";
import Features from "./Features";
import FeaturesCard from "./FeatureCard";
import HomeTestimonials from "./HomeTestimonials";
import CallBack from "./CallBack";
import TeamSection from "./TeamSection";
import Sponsors from "./Sponsors";
import Preloader from "../../shares/Preloader";
import DynamicMetaTags from "../../components/DynamicMetaTags";

import { useData } from "../../context/Provider";

const Home = () => {
    const { 
        fHomeSliderData, 
        fHomeWhyGetData, 
        fHomeAllOurServicesData, 
        fHomeOurServicesSection, 
        fHomeCallBackData, 
        sponsors 
    } = useData();

    return (
        <>
            <DynamicMetaTags page="home" />
            <Preloader />
            <CustomSlider data={fHomeSliderData} />
            <Features data={fHomeWhyGetData} />
            <FeaturesCard data={fHomeOurServicesSection} CardData={fHomeAllOurServicesData} />
            <HomeTestimonials />
            <CallBack />
            <TeamSection />
            <Sponsors data={sponsors} />
        </>
    );
};

export default Home;
